.modal__content {
    margin-top: 0px;
    text-align: center;
}

.modal__form__container {
    height: 300px; /* Adjust the height as needed */
    overflow-y: auto; /* Enables vertical scrollbar */
    /* border: 1px solid #ccc; Optional border for visibility */
    padding: 10px;
    box-sizing: border-box; /* Ensure padding is included in height */
}

.confirmText {
    margin-top: 0rem;
    font-size: 1.25rem;
    font-weight: 600;
}

#cancel {
    width: 5.25rem;
    height: 1.65rem;
    background-color: #EAE9E9;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    margin: 2px 10px;
    padding: 0;
    border-radius: 1rem;
}

#confirm {
    width: 5.25rem;
    height: 1.65rem;
    background-color: #3F8EE8;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
    margin: 2px 10px;
    padding: 0;
    border-radius: 1rem;
}

.icon {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 0.5rem;
}

.icon__container {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 0.5rem; */
    padding-right: 3.6rem;
}