
.download-card-background {
    background-color: #F5F5F5;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    min-width: 1000px;
    min-height: 40rem;
    position: relative;
}

.download-card {
    background-color: #FFFFFF;
    border-radius: 1.6875rem;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);;
    width: 28.5rem;
    height: 30rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.title-area {
    width: 28.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2rem;
}

.download-icon {
    width: 4rem;
    margin-right: 1rem;
}

.card p {
    color: #757575;
}

input.device-id {
    width: 2.25rem;
    height: 2.5rem;
    margin: 0.25rem 0.25rem;
    font-size: 2.25rem;
    text-align: center;
    border: none;
    border-bottom: 1px solid #757575;
}

input.device-id:focus {
    outline: none;
}

input.device-id::placeholder {
    /* color: #757575; */
    color: #E8E6E680;
    font-size: 2.25rem;
    text-align: center;
}

input.device-id:disabled {
    background-color: #FFFFFF;
}

button.device-id {
    /* width: 9.25rem; */
    height: 3rem;
    background-color: #3F8EE8;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 700;
    color: #FFFFFF;
    margin: 2rem 0;
    padding: 0rem 0rem;
    border-radius: 1.5rem;
}

button.device-id:disabled {
    color: #BEC0CA;
    background-color: #EAE9E9;
    cursor: not-allowed;
}

button.left {
    background-color: #FFFFFF;
    border: none;
}

button.left:hover {
    /* background-color: #F5F5F5; */
    cursor: pointer;
}