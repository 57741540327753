.cert-title {
    color: #000;
    font-family: Noto Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.cert-table {
    margin-top: 1rem;
    margin-bottom: 5rem;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.cert-table thead tr {
    border-bottom: 1px solid #000;
}

.cert-table tbody tr {
    border-bottom: 1px solid #ADABAB;
    height: 3rem;
}

.cert-table tbody tr:last-child {
    border-bottom: none;
}

.cert-table th {
    text-align: left;
    height: 30px;
    /* padding: 0.5rem 0.5rem;
    font-family: Noto Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal; */
}

.product-info {
    color: #000;
    font-family: Noto Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 60%;
    margin-bottom: 5rem;
}

.company-info {
    color: #000;
    font-family: Noto Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5rem;
}

.product-info tr {
    height: 30px;
}

.company-name {
    color: #000;
    font-family: Noto Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.bold {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.description {
    color: #000;
    text-align: justify;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}