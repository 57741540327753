.svgIcon path {
    fill: #FFFFFF;
    fill-opacity: 100%;
}

button:disabled .svgIcon path {
    fill: #000000;
    fill-opacity: 20%;
}

.svgIcon {
    min-width: 24px;
}

.head-button {
    background-color: #ffffff00;
    border: none;
}

.head-button:hover {
    cursor: pointer;
}