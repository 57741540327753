.background {
    width: 210px;
    height: 210px;
    z-index: 1;
    margin: 18px auto;
    
}

.progress {
    transform: rotate(-90deg);
    margin-top: -241px;
    margin-bottom: -50px;
}

.percent {
    text-align: center;
    z-index: 4;
    margin: -98px 0 0px 0;
    font-size: 48px;
    color: #FF3838;
}

.indicator {
    width: 236px;
    height: 236px;
    z-index: 3;
    margin-top: -145px;
}


