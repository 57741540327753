input::placeholder.info {
  /*color: #000;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: center;
    /* padding-left: 10px; */

    color: rgba(0, 0, 0, 0.2);
}

input[type="text"].info {
    /*color: #000;*/
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 114% */
        /*display: flex;*/
        /*align-items: center;*/
        padding-left: 10px;

        /*color: rgba(0, 0, 0, 0.2);*/
}

textarea::placeholder {
    /*color: #000;*/
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */
        display: flex;
        align-items: center;
        padding-left: 10px;

        color: rgba(0, 0, 0, 0.2);
}

textarea.info {
    /*color: #000;*/
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 114% */
        /*display: flex;*/
        /*align-items: center;*/
        padding-left: 10px;

        /*color: rgba(0, 0, 0, 0.2);*/
}

.help-icon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}

input[type=submit].info {
    background-color: #3F8EE8;
    color: #FFFFFF;
    width: 392px;
    height: 40px;
    border: none;
    font-size: 14px;
    border-radius: 4px;
    margin: 5px 0 10px 0;
}

input[type=submit]:hover.info {
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
}

input[type=submit]:enabled:active.info {
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input[type=submit]:disabled.info {
    background-color: #d3d1d1;
    color: #9E9E9E;
    cursor: not-allowed;
}

input[type=button].info {
    background-color: #3F8EE8;
    color: #FFFFFF;
    width: 392px;
    height: 40px;
    border: none;
    font-size: 14px;
    border-radius: 4px;
    margin: 5px 0 10px 0;
}

input[type=button]:hover.info {
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
}

input[type=button]:enabled:active.info {
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input[type=button]:disabled.info {
    background-color: #d3d1d1;
    color: #9E9E9E;
    cursor: not-allowed;
}




