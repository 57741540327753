.rangebar {
    width: 94%;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 16px;
    position: relative;
    /* overflow: hidden; */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin: 5px auto;
    /* padding: 10px; */
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.rangebar .rangebar__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    /* overflow: hidden; */
}

.rangebar__container .rangebar__container__title {
    height: 40px;
    width: 20%;
    font-size: 1rem;
    font-weight: 600;
    color: #53545C;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    text-align: center;
    /* border: 1px solid #757575; */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.rangebar__container__title p {
    margin: 0;
    padding: 0;
    line-height: 40px;
}


.rangebar__container .rangebar__container__item {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}

.rangebar__container__item button {
    width: 100%;
    height: 36px;
    background-color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    margin: 2px 10px;
    padding: 0;
    border-radius: 30px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
}

.rangebar__container__item button:hover {
    background-color: #F5F5F5;
}

.rangebar__container__item button:active {
    background-color: #E0E0E0;
}

.rangebar__container__item button.active {
    color: #FFFFFF;
    background-color: #469DFF;
}

.vline {
    width: 1px;
    height: 80%;
    margin: 0 15px;
    background-color: #E8E6E6;
    /* position: absolute;
    top: 0;
    left: 0;
    z-index: 1; */
}


