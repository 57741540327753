.title {
    font-size: 1.25rem;
    font-weight: 600;
    font-family: Roboto;
    margin-bottom: 1rem;
    text-align: center;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10em;
}

.modal__block {
    width: 46%;
}

.modal__form__display {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-evenly;
    padding: 1rem;
}

.modal__container {
    width: 100%;
}