/* .card {
    transition: transform .5s;
}

.card:hover {
    transform: scale(1.05);
} */

.vline {
    width: 1px;
    height: 75%;
    margin: auto 10px;
    background-color: #E8E6E6;
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    /* z-index: 1; */
}

.row-plots {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin: 0 auto;
}

@media screen and (max-width: 1380px) {
    .row-plots {
        width: 100%;
    }
}

@media screen and (max-width: 720px) {
    .row-plots {
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}