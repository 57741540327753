.rangebar__edit {
    width: 80%;
    height: 40px;
    background-color: #FFFFFF;
    border-radius: 16px;
    position: relative;
    /* overflow: hidden; */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    /* padding: 10px; */
    margin: 5px auto;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.rangebar__edit .rangebar__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    /* overflow: hidden; */
}

.rangebar__container .rangebar__container__title {
    height: 40px;
    width: 20%;
    font-size: 1rem;
    font-weight: 600;
    color: #53545C;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    text-align: center;
    /* border: 1px solid #757575; */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
}

.rangebar__container__title p {
    margin: 0;
    padding: 0;
    line-height: 40px;
}


.rangebar__container .rangebar__container__item {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}

.rangebar__container__item button {
    width: 96%;
    height: 36px;
    background-color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    margin: 2px 2px;
    padding: 0;
    border-radius: 30px;
    /* border: 1px solid #E1E2E9; */
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
}

.excursion button {
    width: 96%;
    height: 36px;
    background-color: #FFFFFF;
    /* border: none; */
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #000000;
    margin: 2px 2px;
    padding: 0;
    border-radius: 30px;
    border: 1px solid #E1E2E9;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
}

.rangebar__container__item button:hover {
    background-color: #F5F5F5;
}

.rangebar__container__item button:active {
    background-color: #E0E0E0;
}

.rangebar__container__item button.active {
    color: #FFFFFF;
    background-color: #469DFF;
}

.modal__content {
    width: 80%;
    height: 80%;
    margin: 20px auto;
    background-color: #FFFFFF;
    border-radius: 10px;
    /* padding: 20px; */
    box-sizing: border-box;
    /* position: relative; */
}

.modal__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.modal__form__item {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    /* position: relative; */
}

.modal__form__item__label {
    width: 30%;
    text-align: center;
    display: block;

}

.modal__form__item__input {
    width: 60%;
    height: 1.45rem;
    text-align: center;
    border-radius: 1rem;
    background-color: #EAE9E9;
    border: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.vline {
    width: 1px;
    height: 80%;
    margin: 0 15px;
    background-color: #D9D9D9;
    /* position: absolute;
    top: 0;
    left: 0;
    z-index: 1; */
}

.hline {
    /* height: 1px; */
    width: 100%;
    background-color: #D9D9D9;
    border: 0.5px solid #D9D9D9;
}

.close {
    border: none;
    background-color: #FFFFFF;
    cursor: pointer;
    float: right;
    font-size: 20px;
}

.button__container {
    height: 30px;
    margin: 10px;
}

.save__all {
    width: 4.75rem;
    height: 1.45rem;
    background-color: #3F8EE8;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
    margin: 2px 10px;
    padding: 0;
    border-radius: 1rem;
    float: right;
}

/* .save__all {
    width: 4.75rem;
    height: 1.45rem;
    background-color: #FFFFFF;
    border: 1px solid #000;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    color: #000000;
    margin: 2px 10px;
    padding: 0;
    border-radius: 1rem;
    float: right;
} */

.modal__form__item__input.alarm__config {
    width: 28%;
}

.config__button {
    width: 2.25rem;
    height: 2.25rem;
    cursor: pointer;
    text-align: center;
    border-radius: 3rem;
    border: 1px solid #E1E2E9;
    background-color: #FFFFFF;
    margin: 0 0.5rem;
    /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset; */
}

.config__button:hover {
    background-color: #F5F5F5;
}

.config__button:active {
    background-color: #E0E0E0;
}

.config__button.active {
    background-color: #469DFF;
    color: #FFFFFF;
}

.config__button.hidden {
    visibility: hidden;
}

.modal__form__item__title {
    width: 33%;
    text-align: center;
    margin-top: 10px;
    display: block;
    font-family: 'Roboto';
    font-size: 1.125rem;
    font-weight: 500;
}

.device__table {
    width: 100%;
    border-radius: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.device__table tr td {
    height: 2.1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.device__table tr td button {
    padding-top: 3%;
    width: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    text-align: center;
    background-color: #FFFFFF;
    border: none;
    /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset; */
}

.device__table tr td p {
    width: 100%;
    margin-left: 2rem;
    font-size: 1rem;
    font-weight: 600;
}

button.switch {
    /* position: relative;
    display: inline-block; */
    /* width: 100px; */
    /* height: 34px; */
    border: none;
    background-color: #FFFFFF00;
}

button.switch:hover {
    /* background-color: #F5F5F5; */
    cursor: pointer;
    
}

.svgIcon {
    min-width: 24px;
 }
