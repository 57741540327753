
lable.login {
  display: block;
  /* margin-bottom: 10px; */
}

/* input.login {
    display: flex;
} */

input.login::placeholder {
  /*color: #000;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: center;
    padding-left: 0px;

    color: rgba(0, 0, 0, 0.2);
}

input.login {
    /*color: #000;*/
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 114% */
        /*display: flex;*/
        /*align-items: center;*/
        padding-left: 10px;
        width: 20.6rem;
        height: 1.8rem;
        border: 1px solid #000000;
        /* border-radius: 4px; */
        margin: 5px 0 10px 0;

        border-radius: 1rem;
        background: #FFF;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) inset;
        /*color: rgba(0, 0, 0, 0.2);*/
}

.login-button:link {
    text-decoration: none;
    color: #000000;
}

.login-button:visited {
    text-decoration: none;
    color: #000000;
}

.login-button:hover {
    text-decoration: none;
    color: #000000;
}

.login-button:active {
    text-decoration: none;
    color: #000000;
}

/* .submit-button {
    width: 392px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px 0 10px 0;
}

.submit-button:hover {
    background: #000000;
    color: #FFFFFF;
} */

input[type=submit].login{
    width: 392px;
    height: 40px;
    background: #3F8EE8;
    border: none;
    font-size: 14px;
    margin: 5px 0 10px 0;
    color: #FFFFFF;
}

input[type=submit]:hover.login{
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
}


input[type=submit]:enabled:active.login{
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input[type=button].login{
    background-color: #FFFFFF;
    color: #3F8EE8;
    width: 392px;
    height: 40px;
    border: none;
    font-size: 14px;
    border-radius: 4px;
    margin: 5px 0 10px 0;
}

input[type=button]:hover.login{
    cursor: pointer;
    background: #FFFFFF;
    color: #367dcf;
}

input[type=button]:enabled:active.login{
    cursor: pointer;
    background: #FFFFFF;
    color: #367dcf;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}