button.blue {
    height: 3rem;
    background-color: #3F8EE8;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 700;
    color: #FFFFFF;
    margin: 2rem 0;
    padding: 0rem 0rem;
    border-radius: 1.5rem;
}

a.blue {
    /* height: 3rem; */
    background-color: #3F8EE8;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    border-radius: 1.5rem;
    padding: 0.8rem 1rem;
}

.link.blue {
    background-color: #3F8EE8;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 1.125rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    border-radius: 1.5rem;
    padding: 0.8rem 1rem;
}

img.logo:hover {
    cursor: pointer;
}