/* .panel-value {
    height: 45px;
    font-size: 45px;
    text-align: center;
    font-family: Roboto;
    font-weight: bold;
    background-image: linear-gradient(to bottom, #1E2B7C, #3F8EE8);
    -webkit-background-clip: text;
    -webkit-background-size: 100% 100%;
    color: transparent;
} */

.panel {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.panel .panel-container {
    height: 100%;
    width: 18%;
    margin: 10px 10px;
    /* background-color: #FFFFFF; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.panel .panel-container .panel-title-box {
    height: 80px;
    width: 100%;
    margin-bottom: -50px;
    text-align: center;
    font-size: 16px;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    /* background-color: #3F8EE8; */
    border-radius: 25px;
}

.panel-title-box.pass {
    background-color: #469DFF;
}

.panel-title-box.fail {
    background-color: #FF3838;
}

.panel-title {
    /* height: 80px;
    width: 100%; */
    margin: 5px;
    font-size: 16px;
    text-align: center;
    font-family: Roboto;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.panel .panel-container .panel-box {
    height: 80px;
    width: 100%;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.panel .panel-container .panel-box .panel-value {
    height: 45px;
    width: 100%;
    margin: 0px;
    font-size: 45px;
    text-align: center;
    color: #757575;
    font-family: Roboto;
    font-weight: bold;
}