.MenuBar {
    height: 100vh;
    width: 75px;
    background-color: #FFFFFF;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    /*background-color: aquamarine;*/
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.MenuBar.dark {
    background-color: #262729;
}

.logo {
    width: 60%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.menu {
    width: 80%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px 0px;
    margin: 10px 0px;
    outline: none;
    border: none;
    cursor: pointer;
    /*display: inline-block;*/
}

.menu.dark {
    background-color: #262729;
}

.menu:hover {
    background-color: #3F8EE8;
}

.menuIcon {
    /* width: 35%; */
    width: 1.2rem;
    height: 1.2rem;
    margin: 0px 18px;
}

.menu:hover .menuIcon path {
    fill: #FFFFFF;
    fill-opacity: 100%;
}

button:hover .menuIcon path {
    fill: #FFFFFF;
}

.icon {
    width: 1.2rem;
    height: 1.2rem;
    margin: 2px 12px 2px 8px;
}

/*img {*/
/*    width: 35%;*/
/*}*/

/*object {*/
/*    width: 35%;*/
/*    color:white;*/
/*}*/



