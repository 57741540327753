
lable.signup {
    display: block;
    /* margin-bottom: 10px; */
}

/* input.signup {
    display: flex;
} */
  
input.signup::placeholder {
/*color: #000;*/
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: center;
    padding-left: 0px;

    color: rgba(0, 0, 0, 0.2);
}
  
input.signup {
    color: #000;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    padding-left: 10px;
    width: 21rem;
    height: 1.8rem;
    border: 1px solid #ADABAB;
    margin: 5px 0 10px 0;

    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) inset;
}

.signup-button:link {
    text-decoration: none;
    color: #000000;
}

.signup-button:visited {
    text-decoration: none;
    color: #000000;
}

.signup-button:hover {
    text-decoration: none;
    color: #000000;
}

.signup-button:active {
    text-decoration: none;
    color: #000000;
}

/* .submit-button {
    width: 392px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px 0 10px 0;
}

.submit-button:hover {
    background: #000000;
    color: #FFFFFF;
} */

input[type=submit].signup{
    width: 392px;
    height: 40px;
    background: #3F8EE8;
    border: none;
    font-size: 14px;
    margin: 5px 0 10px 0;
    color: #FFFFFF;
}

input[type=submit]:hover.signup{
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
}


input[type=submit]:enabled:active.signup{
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input[type=submit]:disabled.signup{
    cursor: not-allowed;
    opacity: 0.5;
}

input[type=button].signup{
    background-color: #3F8EE8;
    color: #FFFFFF;
    width: 392px;
    height: 40px;
    border: none;
    font-size: 14px;
    border-radius: 4px;
    margin: 5px 0 10px 0;
}

input[type=button]:hover.signup{
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
}

input[type=button]:enabled:active.signup{
    cursor: pointer;
    background: #367dcf;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

select#countryCode {
    width: 5rem;
    margin-right: 1.2rem;
}

input#phoneNumber {
    width: 15rem;
}

select.signup {
    appearance: none;
    /* safari */
    -webkit-appearance: none;
    /* other styles for aesthetics */
    width: 5rem;
    height: 2rem;
    line-height: 16px;
    margin-right: 1.2rem;
    /* font-size: 1.15rem; */
    background-color: #FFFFFF;
    border: 1px solid #ADABAB;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) inset;
    border-radius: 1rem;
    color: #000;
    cursor: pointer;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    padding-left: 10px;
}
/* style={{width: '5rem', height: '2rem', borderRadius: '1.5rem', marginLeft: '0.5rem'}} */

p.complete {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem; /* 214.286% */
    margin: 0;
}

p.complete.email {
    color: #000;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem;
}